@import '~@eika-infrastruktur/style-web-lib/src/scss/themes/blue-theme';
@import '~@eika-infrastruktur/style-web-lib/src/scss/partials/variables';

.cookie-modal {
    font-size: 16px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    height: 100%;

    overflow-y: auto;

    border: none;
    border-radius: 12px;

    display: none;

    padding: 40px 24px;
    outline: none;

    &.open {
        display: flex;
        background-color: #fff;

        div[data-focus-lock-disabled] {
            display: flex;
        }

        #focus-container, .cookie-modal__content {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            &:focus {
                outline: none !important;
            }
        }
    }

    .content {
        padding-bottom: 50px;
    }

    @media screen and (min-width: 768px) {
        height: revert;
        max-width: 602px;
        max-height: min(90vh, 750px);
        top: calc(50% - 41vh);
        left: 50%;
        transform: translateX(-50%);
    }

    &:focus {
        outline: none !important;
        border: none;
    }
}

.cookie-modal__backdrop {
    position: fixed;

    align-items: center;

    justify-content: center;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 500;

    opacity: 0;
    display: none;

    @media screen and (min-width: 768px) {
        background-color: #021717;
        transition: opacity var(--backdrop-transition-in-duration) ease 0.1s;
        &.open {
            display: block;
            opacity: 0.9;
            transition: opacity var(--animation-duration) ease 0.1s;
        }
    }
}
