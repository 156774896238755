@import '~@eika-infrastruktur/style-web-lib/src/scss/themes/blue-theme';
@import '~@eika-infrastruktur/style-web-lib/src/scss/partials/variables';


.cookie-modal,
.card.standard-card {
    .header {
        top: 0;
        right: 32px;
        margin: 0;
    }

    .title,
    h1 {
        font-size: 22px;
        font-weight: 700;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }

    h3 {
        margin: 15px 0;
        font-size: 18px;
    }

    ul {
        margin: 0;
        padding: 0 16px;

        li {
            margin-top: 16px;
        }
    }
}

.card-ingress {
    margin-bottom: 2em;
    padding: 0;

    ul li {
        margin-top: 24px;
    }
}

.cookie-consent-form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    padding-bottom: 48px;

    .card-button-wrapper {
        margin-top: auto;
    }
}

.card-button-wrapper {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
    margin-top: auto;
    gap: 12px;

    > * {
        margin-top: 24px;
    }

    .card-button-wrapper__button--tertiary {
        grid-column: 1/-1;
        align-self: center;
    }

    @media screen and (min-width: 420px) {
        grid-template-columns: auto auto;
    }
}

.success-card {
    align-self: stretch;
    padding: 24px;
    margin-bottom: 24px;
    text-align: center;
    flex: 1 1 auto;
}

.checkbox input[type='checkbox'] + label {
    font-family: $opensans !important;

    span {
        font-weight: $semiBold;
    }
}
